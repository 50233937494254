import React from "react"
import styled from "styled-components"
import SEO from "../seo"
import Hero from "../PC/organisms/Hero"
import Header from "../PC/organisms/Header"
import IndexNews from "../PC/organisms/IndexNews"
// import IndexProjects from "../PC/organisms/IndexProjects"
import IndexProducts from "../PC/organisms/IndexProducts"
// import IndexRecruit from "../PC/organisms/IndexRecruit"
import Footer from "../PC/organisms/Footer"
import CompanyMembers from "../PC/organisms/CompanyMembers"
import CompanyInfo from "../PC/organisms/CompanyInfo"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import MobileIndexNews from "../mobile/organisms/MobileIndexNews"
import MobileIndexProduct from "../mobile/organisms/MobileIndexProducts"
import MobileCompanyMembers from "../mobile/organisms/MobileCompanyMembers"
import MobileCompanyInfo from "../mobile/organisms/MobileCompanyInfo"
import judgePage from "../judgePage"
import MobileHeader from "../mobile/organisms/MobileHeader"
import MobileFooter from "../mobile/organisms/MobileFooter"
import MobileHero from "../mobile/organisms/MobileHero"

const Template = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: auto;
`

const IndexSEOData = {
    title: "産業をアップデートする",
    lang: "jp",
    url: "/",
    description:
        "製造業、建設業、小売業などの労働集約型旧態産業において、デジタルトランスフォーメーションで産業に変革を起こすテクノロジー集団",
}

const IndexTemplate = ({ posts }) => {
    const page = judgePage('INDEX')
    const breakPoints = useBreakpoint()

    return (
        <Template>
            <SEO
                title={IndexSEOData.title}
                lang={IndexSEOData.lang}
                url={IndexSEOData.url}
                description={IndexSEOData.description}
            />
            {breakPoints.pc ? (
                <React.Fragment>
                    <Hero page={page.name} />
                    <IndexNews posts={posts.news} />
                    {/* <IndexProjects /> */}
                    <IndexProducts posts={posts.products} />
                    {/* <IndexRecruit /> */}
                    <CompanyMembers posts={posts.members} />
                    <CompanyInfo page={page.name} />
                    <Footer />
                    <Header page={page.name} />
                </React.Fragment>
            ) : null}
            {breakPoints.mobile ? (
                <React.Fragment>
                    <MobileHero page={page.name} />
                    <MobileIndexNews posts={posts.news} />
                    <MobileIndexProduct posts={posts.products} />
                    <MobileCompanyMembers posts={posts.members} />
                    <MobileCompanyInfo page={page.name} />
                    <MobileFooter />
                    <MobileHeader />
                </React.Fragment>
            ) : null}
        </Template>
    )
}

export default IndexTemplate
