import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { ViewAllButton } from "../../PC/atoms/Button"
import MobileIndexTopicTitle from "../atoms/MobileIndexTopicTitle"
import MobileIndexProduct from "../molecules/MobileIndexProduct"
import MobileFluidImages from "../../Images/MobileFluidImages"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 750px;
    margin: 80px 0px;
`

const OuterWide = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1000px;
    margin: 125px 0px;
`

const Background = styled.div`
    width: 100%;
    height: 750px;
`

const BackgroundWide = styled.div`
    width: 100%;
    height: 1000px;
`

const ContentsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 85%;
    min-width: 280px;
    bottom: 750px;
    margin: auto;
`
const ContentsWrapperWide = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 85%;
    min-width: 280px;
    bottom: 1000px;
    margin: auto;
`

const ViewAllButtonWrapper = styled.div``

const ProductsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 16px;
`

const MobileIndexProducts = ({ posts }) => {
    const breakPoints = useBreakpoint()

    if (breakPoints.smartphone) {
        return (
            <Outer>
                <MobileIndexTopicTitle
                    title="PRODUCTS"
                    subtitle="自社開発事業"
                    position="left"
                />
                <Background>
                    <Img
                        fluid={MobileFluidImages("topProductBg")}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <ContentsWrapper>
                        <ViewAllButtonWrapper>
                            <ViewAllButton linkto="https://www.synq-platform.com/" />
                        </ViewAllButtonWrapper>
                        <ProductsWrapper>
                            {posts.slice(0, 1).map((post, index) => (
                                <MobileIndexProduct
                                    thumbnailPath={post.thumbnailPath}
                                    title={post.title}
                                    body={post.body}
                                    name={post.name}
                                    key={index}
                                />
                            ))}
                        </ProductsWrapper>
                    </ContentsWrapper>
                </Background>
            </Outer>
        )
    } else {
        return (
            <OuterWide>
                <MobileIndexTopicTitle
                    title="PRODUCTS"
                    subtitle="自社開発事業"
                    position="left"
                />
                <BackgroundWide>
                    <Img
                        fluid={MobileFluidImages("topProductBg")}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <ContentsWrapperWide>
                        <ViewAllButtonWrapper>
                            <ViewAllButton linkto="https://www.synq-platform.com/" />
                        </ViewAllButtonWrapper>
                        <ProductsWrapper>
                            {posts.slice(0, 1).map((post, index) => (
                                <MobileIndexProduct
                                    thumbnailPath={post.thumbnailPath}
                                    title={post.title}
                                    body={post.body}
                                    name={post.name}
                                    key={index}
                                />
                            ))}
                        </ProductsWrapper>
                    </ContentsWrapperWide>
                </BackgroundWide>
            </OuterWide>
        )
    }
}

export default MobileIndexProducts
