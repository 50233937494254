import { graphql } from "gatsby"
import React from "react"
import IndexTemplate from "../components/templates/IndexTemplate"
import "../layouts/style.css"

const IndexPage = ({ data }) => {
  const newsArticlePosts = []
  const quandoMemberPosts = []
  const productPosts = []

  data.allMarkdownRemark.edges.forEach((post) => {
    const frontmatter = post.node.frontmatter
    const slug = frontmatter.slug.match(/([a-z]{6})(-)([0-9]*)/)
    if (frontmatter.position !== null) {
      const postData = {
        name: frontmatter.slug,
        id: (slug === null) ? -1 : slug[3],
        thumbnailPath: frontmatter.thumbnail,
        position: frontmatter.position,
        jpName: frontmatter.jpName,
        enName: frontmatter.enName,
        order: frontmatter.order,
      }
      quandoMemberPosts.push(postData)
    } else if (frontmatter.date !== null) {
      const frontmatter = post.node.frontmatter
      const slug = frontmatter.slug.match(/([a-z]{4})(-)([0-9]*)/)
      const postData = {
        name: frontmatter.slug,
        id: (slug === null) ? -1 : slug[3],
        date: frontmatter.date,
        title: (frontmatter.title.length >= 41) ? `${frontmatter.title.slice(0, 40)}...` : frontmatter.title,
        thumbnailPath: frontmatter.thumbnail,
        tags: frontmatter.tags,
      }
      newsArticlePosts.push(postData)
    } else if (frontmatter.tags !== null && frontmatter.date === null) {
      const frontmatter = post.node.frontmatter
      const body = post.node.excerpt
      const slug = frontmatter.slug.match(/([a-z]{7})(-)([0-9]*)/)
      const postData = {
        name: frontmatter.slug,
        id: (slug === null) ? -1 : slug[3],
        title: frontmatter.title,
        thumbnailPath: frontmatter.thumbnail,
        client: frontmatter.client,
        tags: frontmatter.tags,
        body: body
      }
      productPosts.push(postData)
    }
  })
  quandoMemberPosts.sort((a, b) => {
    if (a.order <= b.order) {
      return -1
    } else {
      return 1
    }
  })
  newsArticlePosts.sort((a, b) => {
    if (a.date <= b.date) {
      return 1
    } else {
      return -1
    }
  })
  productPosts.sort((a, b) => {
    if (a.id <= b.id) {
      return 1
    } else {
      return -1
    }
  })

  const posts = {
    members: quandoMemberPosts,
    news: newsArticlePosts,
    products: productPosts
  }

  return (
    <IndexTemplate posts={posts} />
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            date
            title
            thumbnail
            tags
            position
            jpName
            enName
            client
            order
          }
          excerpt(pruneLength: 1000)
        }
      }
    }
  }
`

export default IndexPage