import { useStaticQuery, graphql } from "gatsby"

const MobileFluidImages = request => {
    const data = useStaticQuery(graphql`
        query {
            techFigure0: file(relativePath: { eq: "mobile/tech-figure0.png" }) {
                childImageSharp {
                    fluid(maxWidth: 584, maxHeight: 1036) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            techFigure1: file(relativePath: { eq: "mobile/tech-figure1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 642, maxHeight: 634) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            MobileRecruitPhoto0: file(
                relativePath: { eq: "PC/recruit-photo0.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 980, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            MobileRecruitPhoto1: file(
                relativePath: { eq: "PC/recruit-photo1.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 480, maxHeight: 608) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            MobileRecruitPhoto2: file(
                relativePath: { eq: "PC/recruit-photo2.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 485, maxHeight: 297) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            MobileRecruitPhoto3: file(
                relativePath: { eq: "PC/recruit-photo3.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 485, maxHeight: 297) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            MobileRecruitPhoto4: file(
                relativePath: { eq: "PC/recruit-photo4.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 980, maxHeight: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            topProductBg: file(
                relativePath: { eq: "mobile/top-product-bg.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 750, maxHeight: 1735) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            COMPANY: file(
                relativePath: { eq: "mobile/company-hero-bg.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 750, maxHeight: 568) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            RECRUIT: file(
                relativePath: { eq: "mobile/recruit-hero-bg.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 750, maxHeight: 568) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            SERVICE: file(
                relativePath: { eq: "mobile/service-hero-bg.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 750, maxHeight: 568) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            NEWS: file(
                relativePath: { eq: "mobile/news-hero-bg.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 750, maxHeight: 568) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            CONTACT: file(
                relativePath: { eq: "mobile/contact-hero-bg.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 750, maxHeight: 568) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return data[request].childImageSharp.fluid
}

export default MobileFluidImages
