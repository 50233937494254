import React from "react"
import IndexTopicTitle from "../atoms/IndexTopicTitle"
import { ViewAllButton } from "../atoms/Button"
import Img from "gatsby-image"
import styled from "styled-components"
import FluidImages from "../../Images/FluidImages"
import IndexProduct from "../molecules/IndexProduct"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 125px 0px 125px 0px;
`

const ContentsOuter = styled.div`
    width: 100%;
    height: 540px;
`

const Background = styled.div`
    width: 100%;
    height: 100%;
`

const ContentsWrapper = styled.div`
    position: relative;
    display: flex;
    width: 74%;
    height: 100%;
    bottom: 100%;
    margin: auto;
`

const Margin = styled.div`
    display: inline-block;
    width: 140px;
    height: 100%;
`

const ProductsWrapperWide = styled.div`
    position: relative;
    bottom: 20%;
    display: flex;
    justify-content: center;
    width: calc(100% - 140px);
`

const FirstProductWrapper = styled.div`
    position: relative;
    top: 15%;
`

const ProductsWrapper = styled.div`
    position: relative;
    top: 10%;
    display: flex;
    justify-content: center;
    width: calc(100% - 140px);
    height: 100%;
`

const IndexProducts = ({ posts }) => {

    const breakPoints = useBreakpoint()

    return (
        <Outer>
            <IndexTopicTitle title="PRODUCTS" subtitle="自社開発事業" position="left" />
            <ContentsOuter>
                <Background>
                    <Img
                        fluid={FluidImages("ProductBg")}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                </Background>
                <ContentsWrapper>
                    <Margin>
                        <ViewAllButton linkto="https://www.synq-platform.com/" />
                    </Margin>
                    {breakPoints.hdPlus
                        ?
                            <ProductsWrapperWide>
                                <FirstProductWrapper>
                                    <IndexProduct
                                        thumbnailPath={posts[0].thumbnailPath}
                                        title={posts[0].title}
                                        body={posts[0].body}
                                        name={posts[0].name}
                                    />
                                </FirstProductWrapper>
                            </ProductsWrapperWide>
                        :
                            <ProductsWrapper>
                                <IndexProduct
                                    thumbnailPath={posts[0].thumbnailPath}
                                    title={posts[0].title}
                                    body={posts[0].body}
                                    name={posts[0].name}
                                />
                            </ProductsWrapper>
                    }
                </ContentsWrapper>
            </ContentsOuter>
        </Outer>
    )
}

export default IndexProducts