import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"
import React from "react"
import styled from "styled-components"

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 280px;
    margin: 16px 0px;
`

const ThumbnailImgWrapper = styled.a``

const ThumbnailImg = styled.img`
    width: 100%;
    height: 140px;
    object-fit: cover;
    transition: 0.4s filter;
    filter: none;
    &:hover {
        filter: brightness(70%);
    }
`

const Title = styled.a`
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    color: white;
    margin: 8px 0px;
    text-decoration: none;
`

const Body = styled.p`
    color: white;
    line-height: 20px;
`

const RootWide = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 24px 0px;
`

const ThumbnailImgWide = styled.img`
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: 0.4s filter;
    filter: none;
    &:hover {
        filter: brightness(70%);
    }
`

const TitleWide = styled.a`
    font-size: 26px;
    line-height: 32px;
    font-weight: 300;
    color: white;
    margin: 16px 0px;
    text-decoration: none;
`

const MobileIndexProduct = ({ thumbnailPath, title, body, name }) => {
    const breakPoints = useBreakpoint()

    return (
        <React.Fragment>
            {breakPoints.smartphone ? (
                <Root>
                    <ThumbnailImgWrapper href="https://www.synq-platform.com/">
                        <ThumbnailImg src={thumbnailPath} />
                    </ThumbnailImgWrapper>
                    <Title href={`/products/${name}`}>{title}</Title>
                    <Body>{body.slice(0, 80)}...</Body>
                </Root>
            ) : null}
            {breakPoints.mobileWide ? (
                <RootWide>
                    <ThumbnailImgWrapper href="https://www.synq-platform.com/">
                        <ThumbnailImgWide src={thumbnailPath} />
                    </ThumbnailImgWrapper>
                    <TitleWide href={`/products/${name}`}>{title}</TitleWide>
                    <Body>{body.slice(0, 80)}...</Body>
                </RootWide>
            ) : null}
        </React.Fragment>
    )
}

export default MobileIndexProduct
