import React from "react"
import IndexTopicTitle from "../atoms/IndexTopicTitle"
import { ViewAllButton } from "../atoms/Button"
import styled from "styled-components"
import NewsArticle from "../atoms/NewsArticle"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 125px 0px 125px 0px;
`

const ContentsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 470px;
`

const Background = styled.div`
    display: flex;
    width: 83%;
    height: 100%;
    background: black;
`

const Margin = styled.div`
    display: flex;
    width: 180px;
    height: 100%;
`

const MarginSpacer = styled.div`
    display: inline-block;
    width: 32px;
    height: 100%;
`

const ArticlesWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    bottom: 150px;
    border-top: 1px solid #cdcdcd;
`

const IndexNews = ({ posts }) => {

    const breakPoints = useBreakpoint()
    const ArticlesNum = (breakPoints) => {
        if (breakPoints.hdPlus) {
            return 4
        } else if (breakPoints.wxga) {
            return 3
        } else {
            return 2
        }
    }

    return (
        <Outer>
            <IndexTopicTitle title="NEWS" subtitle="ニュース" position="left" />
            <ContentsWrapper>
                <Background>
                    <Margin>
                        <MarginSpacer />
                        <ViewAllButton linkto="/news" />
                    </Margin>
                    <ArticlesWrapper>
                        {posts.slice(0, ArticlesNum(breakPoints)).map((post, index) => (
                            <NewsArticle
                                date={post.date}
                                title={post.title}
                                thumbnailPath={post.thumbnailPath}
                                tags={post.tags}
                                requireBorderRight={index === ArticlesNum(breakPoints) - 1 ? true : false}
                                name={post.name}
                                key={index}
                            />
                        ))}
                    </ArticlesWrapper>
                </Background>
            </ContentsWrapper>
        </Outer>
    )
}

export default IndexNews