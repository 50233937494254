import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { ViewAllButton } from "../../PC/atoms/Button"
import MobileIndexTopicTitle from "../atoms/MobileIndexTopicTitle"
import MobileNewsArticle from "../atoms/MobileNewsArticle"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 30px 0px;
`

const ContentsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const Background = styled.div`
    width: 96%;
    background: black;
    padding-bottom: 24px;
`

const ViewAllButtonWrapper = styled.div`
    padding: 0px 0px 40px 40px;
`

const ArticlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2vw;
`

const ArticlesWrapperWide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const MobileIndexNews = ({ posts }) => {
    const breakPoints = useBreakpoint()
    return (
        <Outer>
            <MobileIndexTopicTitle
                title="NEWS"
                subtitle="ニュース"
                position="left"
            />
            <ContentsWrapper>
                <Background>
                    <ViewAllButtonWrapper>
                        <ViewAllButton linkto="/news" />
                    </ViewAllButtonWrapper>
                    {breakPoints.smartphone ? (
                        <ArticlesWrapper>
                            {posts.slice(0, 4).map((post, index) => (
                                <MobileNewsArticle
                                    date={post.date}
                                    title={post.title}
                                    thumbnailPath={post.thumbnailPath}
                                    tags={post.tags}
                                    name={post.name}
                                    key={index}
                                />
                            ))}
                        </ArticlesWrapper>
                    ) : (
                        <ArticlesWrapperWide>
                            {posts.slice(0, 4).map((post, index) => (
                                <MobileNewsArticle
                                    date={post.date}
                                    title={post.title}
                                    thumbnailPath={post.thumbnailPath}
                                    tags={post.tags}
                                    name={post.name}
                                    key={index}
                                />
                            ))}
                        </ArticlesWrapperWide>
                    )}
                </Background>
            </ContentsWrapper>
        </Outer>
    )
}

export default MobileIndexNews
