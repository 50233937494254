import { useBreakpoint } from 'gatsby-plugin-breakpoints/BreakpointProvider'
import React from 'react'
import styled from "styled-components"
import { ReadMoreButton } from '../../PC/atoms/Button'

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 270px;
    height: 400px;
    border: 1px solid #cdcdcd;
    letter-spacing: 1px;
    color: black;
    text-decoration: none;
    background: white;
    margin-bottom: 20px;
`

const OuterWide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 560px;
    border: 1px solid #cdcdcd;
    letter-spacing: 1px;
    color: black;
    text-decoration: none;
    background: white;
    margin-bottom: 40px;
`

const MobileNewsArticle = ({
    date,
    title,
    thumbnailPath,
    tags,
    name,
}) => {

    const breakPoints = useBreakpoint()

    return (
        <React.Fragment>
            {breakPoints.smartphone
                ?
                    <Outer href={`/news/${name}`}>
                        <NewsArticleInner
                            thumbnailPath={thumbnailPath}
                            date={date}
                            title={title}
                            tags={tags}
                            name={name}
                        />
                    </Outer>
                :
                    null
            }
            {breakPoints.mobileWide
                ?
                    <OuterWide href={`/news/${name}`}>
                        <NewsArticleInner
                            thumbnailPath={thumbnailPath}
                            date={date}
                            title={title}
                            tags={tags}
                            name={name}
                        />
                    </OuterWide>
        :
            null

            }
        </React.Fragment>
    )
}

const ThumbnailImgWrapper = styled.a``

const ThumbnailImg = styled.div`
    width: 270px;
    height: 180px;
    background: url(${props => props.background}) center center no-repeat;
    background-size: contain;
    transition: 0.4s filter;
    filter: none;
    &:hover {
        filter: opacity(70%);
    }
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    height: calc(100% - 132px);
    padding: 24px;
`

const Info = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 10px 0px;
`

const Date = styled.p`
    display: inline-block;
    font-weight: 500;
`

const Category = styled.p`
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    color: white;
    padding: 3px 4px;
    letter-spacing: 1px;
`

const Title = styled.a`
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: black;
    text-decoration: none;
`

const ReadMoreButtonWrapper = styled.div`
    margin-top: auto;
`

const ThumbnailImgWide = styled.img`
    width: 400px;
    height: 250px;
    object-fit: cover;
    transition: 0.4s filter;
    filter: none;
    &:hover {
        filter: opacity(70%);
    }
`

const TitleWide = styled.a`
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    color: black;
    text-decoration: none;
`

const ReadMoreButtonWrapperWide = styled.div`
    margin-top: auto;
`


const NewsArticleInner = ({
    thumbnailPath,
    date,
    title,
    tags,
    name,
}) => {

    const breakPoints = useBreakpoint()
    const category = tags[0]
    const categoryColor = (category) => {
        switch (category) {
            case 'イベント': {
                return '#EABEBF'
            }
            case 'ニュース': {
                return '#A5DEE5'
            }
            case 'メディア掲載': {
                return '#D6A3DC'
            }
            case 'About Us': {
                return '#F5B994'
            }
            case 'お知らせ': {
                return '#80BEAF'
            }
            case 'プレスリリース': {
                return '#F7DB70'
            }
            case '登壇情報': {
                return '#808e9c'
            }
            case 'プロダクト情報': {
                return '#8095CE'
            }
            case '採用関連情報': {
                return '#B3DDD1'
            }
            case 'テスト': {
                return '#B3DDD1'
            }
            default: {
                return 'none'
            }
        }
    }

    return (
        <>
            {breakPoints.smartphone
                ?
                    <React.Fragment>
                        <ThumbnailImgWrapper href={`/news/${name}`}>
                            <ThumbnailImg background={thumbnailPath} />
                        </ThumbnailImgWrapper>
                        <Inner>
                            <Info>
                                <Date>{date}</Date>
                                <Category
                                    style={{ background: categoryColor(category) }}
                                >
                                    {category}
                                </Category>
                            </Info>
                            <Title href={`/news/${name}`}>{title}</Title>
                            <ReadMoreButtonWrapper>
                                <ReadMoreButton href={`/news/${name}`} />
                            </ReadMoreButtonWrapper>
                        </Inner>
                    </React.Fragment>
                :
                    null
            }
            {breakPoints.mobileWide
                ?
                    <React.Fragment>
                        <ThumbnailImgWrapper href={`/news/${name}`}>
                            <ThumbnailImgWide src={thumbnailPath} alt='thumbnail' loading='lazy' />
                        </ThumbnailImgWrapper>
                        <Inner>
                            <Info>
                                <Date>{date}</Date>
                                <Category
                                    style={{ background: categoryColor(category) }}
                                >
                                    {category}
                                </Category>
                            </Info>
                            <TitleWide href={`/news/${name}`}>{title}</TitleWide>
                            <ReadMoreButtonWrapperWide>
                                <ReadMoreButton href={`/news/${name}`} />
                            </ReadMoreButtonWrapperWide>
                        </Inner>
                    </React.Fragment>
        :
            null

            }
        </>
    )
}

export default MobileNewsArticle