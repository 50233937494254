import React from "react"
import styled from "styled-components"

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0px 16px;
`

const ThumbnailImgWrapper = styled.a``

const ThumbnailImg = styled.img`
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: 0.4s filter;
    filter: none;
    &:hover {
        filter: brightness(70%);
    }
`

const Title = styled.a`
    font-size: 28px;
    font-weight: 300;
    color: white;
    margin: 24px 0px;
    text-decoration: none;
`

const Body = styled.p`
    color: white;
    line-height: 24px;
    letter-spacing: 1px;
`

const IndexProduct = ({
    thumbnailPath,
    title,
    body,
    name
}) => {

    return (
        <Root>
            <ThumbnailImgWrapper href="https://www.synq-platform.com/">
                <ThumbnailImg src={thumbnailPath} />
            </ThumbnailImgWrapper>
            <Title href={`/products/${name}`}>{title}</Title>
            <Body>{body.slice(0, 80)}...</Body>
        </Root>
    )
}

export default IndexProduct
